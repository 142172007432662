// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-atraction-page-js": () => import("../src/templates/atraction-page.js" /* webpackChunkName: "component---src-templates-atraction-page-js" */),
  "component---src-templates-about-page-js": () => import("../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-success-page-js": () => import("../src/templates/success-page.js" /* webpackChunkName: "component---src-templates-success-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atrakcje-js": () => import("../src/pages/atrakcje.js" /* webpackChunkName: "component---src-pages-atrakcje-js" */),
  "component---src-pages-kontakt-js": () => import("../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-pokoje-js": () => import("../src/pages/pokoje.js" /* webpackChunkName: "component---src-pages-pokoje-js" */)
}

